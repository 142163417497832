<template>
  <div class="table-responsive">
    <table class="table table-hover text-nowrap">
      <thead>
        <tr>
          <th>Company Name</th>
          <th>OCN</th>
          <th>Registered On</th>
          <th>Status</th>
          <th>AS</th>
          <th>VS</th>
          <th>SS</th>
          <th>CS</th>
          <th>Number Count</th>
        </tr>
      </thead>
      <tbody v-if="items && !!items.length">
        <tr
          v-for="item in items"
          :key="item.id"
        >
          <td>{{ item.company_name }}</td>
          <td>{{ item.ocn }}</td>
          <td>{{ moment(item.created_on).format('YYYY-MM-DD') }}</td>
          <td>{{ item.status }}</td>
          <td>{{ item.as_cps }}</td>
          <td>{{ item.vs_cps }}</td>
          <td>{{ item.ss_cps }}</td>
          <td>{{ item.cs_cps }}</td>
          <td>{{ item.ca_count }}</td>
          <!--          <td>{{ moment(item.created_on).format('YYYY-MM-DD')}}</td>-->
        </tr>
      </tbody>
      <tbody v-else>
        <td
          colspan="6"
          class="text-center font-weight-bold py-4"
        >
          <h4 class="text-secondary">
            NO VALUES FOUND
          </h4>
        </td>
      </tbody>
    </table>
    <a-pagination
      class="pagination-right"
      :default-current="1"
      :total="10"
      @change="pagination($event)"
    />
  </div>
</template>

<script>
    import axios from '@/axios'
    import moment from 'moment'
    import {Modal} from 'ant-design-vue'

    export default {
        name: 'AdminPayment',
        data() {
            return {
                moment,
                step: 10,
                skip: 0,
                total: null,
                items: [
                {
                  company_name: 'Company name',
                  created_on: '12.12.12',
                  as_cps: 'test',
                  vs_cps: 'test',
                  ss_cps: 'test',
                  cs_cps: 'test',
                  ca_count: 3,
                  ocn: 'test',
                  status: 'Status',
                },
                {
                  company_name: 'Company name',
                  created_on: '12.12.12',
                  as_cps: 'test',
                  vs_cps: 'test',
                  ss_cps: 'test',
                  cs_cps: 'test',
                  ca_count: 3,
                  ocn: 'test',
                  status: 'Status',
                },
                {
                  company_name: 'Company name',
                  created_on: '12.12.12',
                  as_cps: 'test',
                  vs_cps: 'test',
                  ss_cps: 'test',
                  cs_cps: 'test',
                  ca_count: 3,
                  ocn: 'test',
                  status: 'Status',
                }
              ],
                notFound: false
            }
        },
        mounted() {
            // this.loadItems()
        },
        methods: {
            pagination(int) {
                this.skip = 0
                this.skip += this.step * int - this.step
                this.loadItems()
            },
            loadItems() {
                axios
                    .get(`/admin/listing/payment?order_dir=ASC&order_by=created_on&take=10&skip=${this.skip}`)
                    .then((res) => {
                        this.items = res.data.data
                        if (Object.keys(this.items).length === 0) {
                            this.notFound = false
                        } else {
                            this.notFound = true
                        }
                        this.total = res.data.count > this.step ? res.data.count : null
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },
        },
    }
</script>

<style scoped>
    .pagination-right {
        text-align: right !important;
        margin-right: 86px !important;
    }
</style>